.App {
  background-color: #1c1d1f;
  min-height: 100vh;
}

.AppContainer {
  min-height: 86vh;
  background-color: #161616;
}

.Theme {
  background-color: #161616;
  color: white;
  font-size: calc(6px + 1vmin);
}

.AppFooter {
  background-color: #161616;
  color: white;
  bottom: 0;
  position: sticky;
  /* Center horizontally*/
  text-align: center;
}

.AppFooter-content {
  background-color: #161616;
  z-index: 999;
}

.InfoCard {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.TitleCard {
  padding-top: -4crem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.InfoCardCenter {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.AppFooterCard {
  padding: 1rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 900s linear;
  }
}

.CsodomNavbar {
  background-color: #161616;
  color: white;
  top: 0;
  position: sticky;
  z-index: 999;
}

.App-header {
  background-color: #161616;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center horizontally*/
  text-align: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Alphacraft-header {
  background-image: url("/public/alpha1.png");
  background-position: center;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center horizontally*/
  text-align: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-top: 1rem;
}

.CDS-header {
  background-image: url("/public/stock_books.jpeg");
  background-position: center;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center horizontally*/
  text-align: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-top: 1rem;
}

.JavaChess-header {
  background-image: url("/public/chess1.jpeg");
  background-position: center;
  background-size: cover;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center horizontally*/
  text-align: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-top: 1rem;
}

.UAH-header {
  background-image: url("/public/uah1.jpg");
  background-position: center;
  background-size: cover;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center horizontally*/
  text-align: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-top: 1rem;
}

.SAIC-header {
  background-image: url("/public/saic1.png");
  background-position: right;
  background-size: cover;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center horizontally*/
  text-align: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-top: 1rem;
}

.Amazon-header {
  background-color: black;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center horizontally*/
  text-align: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-top: 1rem;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
